import React from 'react';
import 'i18n';
import { Provider } from 'react-redux';
import { FrRouting } from 'app/shared/fr-routing/FrRouting';
import { PersistGate } from 'redux-persist/integration/react';
import createStore from '../store';
import './App.scss';

export const { store, persistor } = createStore();

export const App = (): React.ReactElement => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <FrRouting />
                </PersistGate>
            </Provider>
        </React.StrictMode>
    );
};
