import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEn from './translations/translations.en.json';
import translationsEs from './translations/translations.es.json';
import translationsCa from './translations/translations.ca.json';

i18next
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem('lng') || 'es',
        // lng: 'es',
        resources: {
            en: {
                translation: translationsEn,
            },
            es: {
                translation: translationsEs,
            },
            ca: {
                translation: translationsCa,
            },
        },
    })
    .then((): void => {
        i18next.addResource('es', 'translation', 'else', 'shotgun');
    });

export default i18next;
