export const DONATION_SUCCESS = 'DONATION_SUCCESS';
export const ANONYMOUS_LOGIN_SUCCESS = 'ANONYMOUS_LOGIN_SUCCESS';
export const REGISTER_LOGIN_SUCCESS = 'REGISTER_LOGIN_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const FILE_PDF = 'FILE_PDF';
export const MAKE_DONATION_FOR_PAYMENT = 'MAKE_DONATION_FOR_PAYMENT';
export const SET_DONATION_AMOUNT = 'SET_DONATION_AMOUNT';
export const SET_METRICS = 'SET_METRICS';
export const SET_PERIODICITY = 'SET_PERIODICITY';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_MOVIMIENTOS = 'SET_MOVIMIENTOS';
export const SET_ADDAPTA_TOKEN = 'SET_ADDAPTA_TOKEN';
export const SET_TOTEM_CONTENT = 'SET_TOTEM_CONTENT';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
