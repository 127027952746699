import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Environment } from 'environment';
import { App } from './app/App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <GoogleOAuthProvider clientId={Environment.CLIENT_ID_GOOGLE}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </GoogleOAuthProvider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
