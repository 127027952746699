export class FrRoutes {

    public static HOME = '/';
    public static START = '/start';

    public static LOGIN = '/login';
    public static SIGNUP = '/signup';
    public static LOGIN_SIGN_IN = '/login';
    public static LOGIN_SIGN_UP = '/login/sign-up';
    public static TRACING = '/tracing';
    public static SCHEDULED_DONATION = '/donaciones-programadas';
    public static TRACING_DETAILS = '/donacion-seguimiento';
    public static DONATION = '/donacion';
    public static RESET_PASSWORD = '/reset-password';
    public static RESET_PASSWORD_WITH_TOKEN = '/reset-password/:token';
    public static SUMMARY = '/donacion-resumen';
    public static APPRECIATION = '/donacion-agradecimiento';
    public static CHECK_DONATION = '/comprobar-donacion';
    public static ERROR = '/error-de-pago';
    public static GATEWAY = '/payment-gateway';
    public static GATEWAY_DENIED = '/payment-gateway/denied';
    public static GATEWAY_APPROVED = '/payment-gateway/approved';

}
