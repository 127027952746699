import packageData from './package.base.json';

export class Environment {

    public static APP_VERSION: string = packageData.version;

    public static API_HOST: string = process.env.REACT_APP_ENV !== 'prod'
        ? 'https://backend.foodration4all.com'
        : process.env.REACT_APP_BASE_URL ?? '';

    public static ADDAPTA_HOST: string = process.env.REACT_APP_ADDAPTA_BASE_URL || '';

    public static API_TIMEOUT: number = 120000; // 50000

    public static CLIENT_ID_APPLE: string = process.env.REACT_APP_ENV === 'prod'
        ? 'com.cuatroochenta.appfoodration.enterprise.webservice'
        : process.env.REACT_APP_APPLE_CLIENT_ID ?? '';

    public static CLIENT_ID_GOOGLE: string = process.env.REACT_APP_ENV === 'prod'
        ? '03195964463-mc375qmgbssgr4k2g6i3qse3p5b6gkut.apps.googleusercontent.com'
        : process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';

    public static CLIENT_ID_FACEBOOK: string = process.env.REACT_APP_ENV === 'prod'
        ? 'com.cuatroochenta.appfoodration.enterprise.webservice'
        : process.env.REACT_APP_FACEBOOK_APP_ID ?? '';

}
