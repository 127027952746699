/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    DONATION_SUCCESS,
    ANONYMOUS_LOGIN_SUCCESS,
    LOGIN_SUCCESS,
    SET_USER_PROFILE,
    FILE_PDF,
    MAKE_DONATION_FOR_PAYMENT,
    SET_DONATION_AMOUNT,
    SET_METRICS,
    SET_PERIODICITY,
    LOGOUT_SUCCESS,
    SET_CONTENT,
    SET_MOVIMIENTOS,
    SET_ADDAPTA_TOKEN,
    SET_TOTEM_CONTENT,
    SET_SUBSCRIPTION,
} from '../types/donation';

const initialState = {
    products: {},
    profile: {},
    userInfo: {},
    paymentDetail: {},
    metrics: {},
    filePDF: {},
    donationAmount: 0,
    periodicity: 'unica',
    textList: {},
    movimientos: {},
    addaptaToken: {
        token: '',
        haveToken: false,
        appID: '',
    },
    totemContent: {},
    subscriptions: [],
};

const donation = (state = initialState, action: any): object => {
    switch (action.type) {
        case DONATION_SUCCESS:
            return {
                ...state,
                products: action.payload,
            };
        case ANONYMOUS_LOGIN_SUCCESS:
            return {
                ...state,
                profile: action.payload,
                userInfo: {},
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                profile: action.payload,
            };
        case SET_USER_PROFILE:
            return {
                ...state,
                userInfo: action.payload,
            };
        case SET_ADDAPTA_TOKEN:
            return {
                ...state,
                addaptaToken: action.payload,
            };
        case FILE_PDF:
            return {
                ...state,
                filePDF: action.payload,
            };
        case MAKE_DONATION_FOR_PAYMENT:
            return {
                ...state,
                paymentDetail: action.payload,
            };
        case SET_DONATION_AMOUNT:
            return {
                ...state,
                donationAmount: action.payload,
            };
        case SET_METRICS:
            return {
                ...state,
                metrics: action.payload,
            };
        case SET_PERIODICITY:
            return {
                ...state,
                periodicity: action.payload,
            };
        case SET_CONTENT:
            return {
                ...state,
                textList: action.payload,
            };
        case SET_TOTEM_CONTENT:
            return {
                ...state,
                totemContent: action.payload,
            };
        case SET_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: action.payload,
            };
        case LOGOUT_SUCCESS:
            return {
                ...initialState,
                addaptaToken: state.addaptaToken,
                totemContent: state.totemContent,
            };
        case SET_MOVIMIENTOS:
            return {
                ...state,
                movimientos: action.payload,
            };
        default:
            return state;
    }
};
export default donation;
