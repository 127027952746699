import React from 'react';
import { Route, Router, HashRouter, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history'; // eslint-disable-line import/no-extraneous-dependencies
import { FrRoutes } from './FrRoutes';

const LoginRouter = React.lazy(() => import('app/login/LoginRouter'));
const SignUpRouter = React.lazy(() => import('app/login/LoginRouter'));
const HomeRouter = React.lazy(() => import('app/home/HomeRouter'));
const ResetPassRouter = React.lazy(() => import('app/login/LoginRouter'));
const ResetPassWithTokenRouter = React.lazy(() => import('app/login/LoginRouter'));
const DonationRouter = React.lazy(() => import('app/donation/DonationRouter'));
const CheckDonationRouter = React.lazy(() => import('app/check-tracing/CheckDonationRouter'));
const SummaryLogOut = React.lazy(() => import('app/summary/SummaryRouter'));
const AppreciationRouter = React.lazy(() => import('app/appreciation/AppreciationRouter'));
const TracingRouter = React.lazy(() => import('app/tracing/TracingRouter'));
const TracingDetialsRouter = React.lazy(() => import('app/tracing-details/TracingDetailsRouter'));
const ErrorRouter = React.lazy(() => import('app/errors/ErrorRouter'));
const ScheduledDonationRouter = React.lazy(() => import('app/scheduled-donation/ScheduledDonationRouter'));

export const FrRouting: React.FC = () => {
    const customHistory = createBrowserHistory();

    return (
        <React.Suspense fallback={null}>
            <Router history={customHistory}>
                <HashRouter>
                    <Switch>
                        <Route exact path={FrRoutes.HOME} component={HomeRouter} />
                        <Route exact path={FrRoutes.LOGIN} component={LoginRouter} />
                        <Route exact path={FrRoutes.SIGNUP} component={SignUpRouter} />
                        <Route exact path={FrRoutes.TRACING} component={TracingRouter} />
                        <Route exact path={FrRoutes.TRACING_DETAILS} component={TracingDetialsRouter} />
                        <Route exact path={FrRoutes.DONATION} component={DonationRouter} />
                        <Route exact path={FrRoutes.CHECK_DONATION} component={CheckDonationRouter} />
                        <Route exact path={FrRoutes.RESET_PASSWORD} component={ResetPassRouter} />
                        <Route exact path={FrRoutes.RESET_PASSWORD_WITH_TOKEN} component={ResetPassWithTokenRouter} />
                        <Route exact path={FrRoutes.DONATION} component={DonationRouter} />
                        <Route exact path={FrRoutes.SUMMARY} component={SummaryLogOut} />
                        <Route exact path={FrRoutes.APPRECIATION} component={AppreciationRouter} />
                        <Route exact path={FrRoutes.ERROR} component={ErrorRouter} />
                        <Route exact path={FrRoutes.SCHEDULED_DONATION} component={ScheduledDonationRouter} />
                        <Route render={() => <Redirect to="/" />} />
                    </Switch>
                </HashRouter>
            </Router>
        </React.Suspense>
    );
};
