import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import reducer from './app/reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'donation',
    ],
};

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const persistedReducer = persistReducer(persistConfig, reducer);
const middleware = [thunk];

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose || compose;
// const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware)),
);

export default (): any => {
    const persistor = persistStore(store);
    return { store, persistor };
};
